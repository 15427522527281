import { gql } from '@apollo/client';
import itemsFragmentQuery from './itemsFragmentQuery';

export function vippedRowQuery({ limit, sort }) {
  const name = `VippedRowQuery_${limit}_${sort}`;
  return gql`
    query ${name} {
      items(filter: { scope: VIPPED }, limit: ${limit}, sort: ${sort}) {
        ...itemFields
      }
    }
    ${itemsFragmentQuery}
  `;
}

export function vippedLimitQuery({ limit, offset }) {
  const name = `VippedLimitQuery${limit}_${offset}`;
  return gql`
    query ${name}($limit: Limit!, $offset: Int!) {
      items(filter: { scope: VIPPED }, limit: $limit, offset: $offset) {
        ...itemFields
      }
    }
    ${itemsFragmentQuery}
  `;
}

export function featuredRowQuery({ limit, offset }) {
  const name = `FeaturedRowQuery_${limit}_${offset}`;
  return gql`
    query ${name}($limit: Limit!, $offset: Int!) {
      items(filter: { scope: FEATURED }, limit: $limit, offset: $offset) {
        ...itemFields
      }
    }
    ${itemsFragmentQuery}
  `;
}

export function searchVippedItemsQuery({ limit, sort, filter }) {
  const filterString = JSON.stringify(filter)
    .replace(/[^\w\s]/g, '')
    .replace(/\s/g, '');
  const name = `SearchVippedItemsQuery${limit}_${sort}_${filterString}`;

  return gql`
    query ${name}(
      $limit: Limit!
      $filter: ItemFilter
      $sort: ItemSort!
    ) {
      items(limit: $limit, filter: $filter, sort: $sort) {
        ...itemFields
      }
    }
    ${itemsFragmentQuery}
  `;
}

export function searchVippedResidencesItemsQuery({ limit, sort, filter }) {
  const filterString = JSON.stringify(filter)
    .replace(/[^\w\s]/g, '')
    .replace(/\s/g, '');
  const name = `SearchVippedResidencesItemsQuery_${limit}_${sort}_${filterString}`;

  return gql`
    query ${name}(
      $limit: Limit!
      $filter: ItemFilter
      $sort: ItemSort!
    ) {
      items(limit: $limit, filter: $filter, sort: $sort) {
        ...itemFields
      }
    }
    ${itemsFragmentQuery}
  `;
}

export const residenceItemsQuery = gql`
  query residenceItemsQuery(
    $filter: ItemFilter
    $limit: Limit!
    $sort: ItemSort!
  ) {
    items(filter: $filter, limit: $limit, sort: $sort) {
      ...itemFields
    }
  }
  ${itemsFragmentQuery}
`;
