import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import BridgeMessagingAPI from './bridgeMessagingAPI';

const initialState = {
  isWebView: false,
  bridgeVersion: 0,
  insets: { top: 0, bottom: 0, left: 0, right: 0 }
};
export const BridgeContext = createContext(initialState);

const initialAPI = {
  closeWebview: BridgeMessagingAPI.closeWebview,
  setNoTopInsetRoutes: BridgeMessagingAPI.setNoTopInsetRoutes
};

export const BridgeAPIContext = createContext(initialAPI);

const bridgeReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'handshake': {
      return {
        ...state,
        isWebView: true,
        bridgeVersion: payload.bridgeVersion
      };
    }
    case 'setInsets': {
      return {
        ...state,
        insets: payload.insets
      };
    }
    default:
      return state;
  }
};

export const BridgeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(bridgeReducer, initialState);

  useEffect(() => {
    BridgeMessagingAPI.handshake();
  }, []);

  useEffect(() => {
    const callback = nativeEvent => {
      const { data } = nativeEvent;

      if (!data) {
        return;
      }

      try {
        const { eventName, params } = JSON.parse(data);

        if (!eventName) {
          return;
        }

        dispatch({
          type: eventName,
          payload: params
        });
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    return BridgeMessagingAPI.subscribe(callback);
  }, []);

  return (
    <BridgeContext.Provider value={state}>
      <BridgeAPIContext.Provider value={initialAPI}>
        {children}
      </BridgeAPIContext.Provider>
    </BridgeContext.Provider>
  );
};

BridgeProvider.propTypes = {
  children: PropTypes.node.isRequired
};
